import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          appointmentlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
          renderfunction:'',
          requested_rehab_id:'',
          setmessage:''
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
   this.listloadedData();
   }
   listloadedData(postobject=''){ 
    var qs = require('qs');
    if(postobject!=''){
      var postobject = postobject
    }else{
     
      var postobject = {
      activePage: 1,
      search_all: '',
      search_all: '',
      listtype: 'new',
      admin_id: localStorage.getItem('admin_id')  
      };
    }
 
   axios.post(apiUrl+"appointment/list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
         this.setState({  appointmentlist: res.data,  totalRecord: res.data.records_count}, function() {
          this.setState({ renderfunction:this.appointmentlist()})
         });
     }else{
      this.setState({  appointmentlist: '', totalRecord: 0}, function() {
        this.setState({ renderfunction:this.appointmentlist()})
      });
     }
   }); 
}

  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
    this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target; 
  var postObject = {             
    activePage   : 1,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };     
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

// deleteuser(id){
//         var qs = require('qs');
//         var postObject = {
//           admin_id : localStorage.getItem("admin_id"),
//           appointment_id  :id
//         };
//         axios.post(apiUrl+"appointment/list",qs.stringify(postObject)).then(res => {      
//           if(res.data.status === 'success'){
//               this.setState({  appointmentlist: res.data, totalRecord: res.data.records_count}, function() {
//                 this.appointmentlist();
//               });
//           }
//         }); 
// }



deleteappointment(){
  
	let stat = ''
		if(this.state.selectedStatus ==='Delete'){
			stat = "D";
      
		}else{
			stat = "F";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          appointmentid  :this.state.selectid,
		  status: stat
        };
        axios.post(apiUrl+"appointment/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}

confirmPopup = (id, center,status) => {
  console.log(center,"requested_rehab_id")
  if(center!=null){
  if(status=="Delete"){
    var setmessage = "delete the appointment";
  }else if(status=="forward"){
    var setmessage = "forward the appointment to rehab center";
  }
  
  this.setState({selectid: id, selectedStatus: status,setmessage:setmessage})	
  $('.showconfirm').addClass('show');	
}else{
  $('.showconvey').addClass('show');
}	
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.deleteappointment();
    $('.showconfirm').removeClass('show');
  }else{
    $('.showconfirm').removeClass('show');	
    $('.showconvey').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"appointment/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}

  appointmentlist() {

    var appointmentlist = this.state.appointmentlist;
    if (appointmentlist != "undefined" && appointmentlist != null) {
      if (appointmentlist.status == "success") {
        if (Object.keys(appointmentlist).length > 0) {
          const appointmentlistDetails = appointmentlist.appointmentlist.map(
            (appointmentlist, Index) => {
              let sno = Index+1;
              if(appointmentlist.app_status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }
              const date = new Date(appointmentlist.appintment_date);
              const month = date.toLocaleString('default', { month: 'long' });
              const day = date.getDate();
              const year = date.getFullYear();
              const formattedTime = `${appointmentlist.hours}:${appointmentlist.minutes} ${appointmentlist.meridiam}`;

              const formattedDateTime = `${month} ${day} ${year} ${formattedTime}`
              // var mobile = ((appointmentlist.phone == null)||(appointmentlist.phone == '')) ?  appointmentlist.phone = 'N/A' : appointmentlist.phone;
              // var year_of_started = ((appointmentlist.year_of_started == null)||(appointmentlist.year_of_started == '')) ?  appointmentlist.year_of_started = 'N/A' : appointmentlist.year_of_started;
              return (
                <tr key={appointmentlist.appointment_id}>
                <td>{sno}</td>
				       <td>
                  {appointmentlist.appointment_code}
                 
                  </td>
                  <td>{appointmentlist.patient_name}</td>
                  <td>${appointmentlist.consultationfees}</td>
                  <td>{appointmentlist.purpose_visit}</td>
                  
                  {/* <td>{gender}</td> */}
                  <td>{appointmentlist.name!=null?appointmentlist.name:'N/A'}</td>
                  
                 
                  <td>{formattedDateTime!=null?formattedDateTime:'N/A'}</td>
                 
                  {/* <td>{appointmentlist.domain_name}</td> */}

				        
                 
				        
                  <td>{appointmentlist.status!=null?appointmentlist.status:'N/A'}</td>
                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/appointment-view/'+appointmentlist.appointment_id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                     {/* <Link to={{ pathname: '/user-edit/'+appointmentlist.id}} className="" title="Duplicate">  <i className="ft-edit" aria-hidden="true"></i></Link> */}
                     {/* <Link onClick={this.confirmPopup.bind(this, appointmentlist.appointment_id,appointmentlist.center_name, "Delete")}  className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                     <Link onClick={this.confirmPopup.bind(this, appointmentlist.appointment_id,appointmentlist.center_name, "forward")}  className="" title="Forward">  <i className="fa fa-forward" aria-hidden="true"></i></Link> */}
                  </td>
                </tr>
              );
            }
          );
          return appointmentlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action showconfirm">
			<p>Are you sure! you want to {this.state.setmessage}?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <div className="confirm-action showconvey">
			<p>Center not available on this appointment</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">OK</a>
		</div>
    <Header />
    <MenuSidebar currentpage="appointments" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            <h3>New Appointments</h3>
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 {/* <a className="" href='/appointment-add'>Add </a> */}
			 {/* <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>*/}
           {/* <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link> */}
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Appt ID</th>
                <th>Patient</th>
                <th>Paid</th>
                <th>Purpose of visit</th>
                
                <th>Provider</th>
                <th>Scheduled On</th>

           
                
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.state.renderfunction}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

export default List;