import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';
import Header from '../Layouts/Header';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import parser from  'html-react-parser';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
     this.inputRef = React.createRef();
      this.state = {
          visitreasons:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
          data_status:'',
          bt_data: [],
          selectstatusid:'',
          visitreasons:[],
          editMode: false,
          editedName: '',
          editItemId: null,
         
      };
      
    
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.handleNameClick = this.handleNameClick.bind(this);
      this.handleNameChange = this.handleNameChange.bind(this);
      this.handleSaveName = this.handleSaveName.bind(this);
     
    }

   
   componentDidMount() {
    this.listloadData()
    // if (this.state.editMode && this.inputRef.current) {
    //   this.inputRef.current.focus();
    // }
   }
   componentDidUpdate(prevProps, prevState) {
    if (this.state.editMode && this.state.editItemId !== prevState.editItemId) {
      this.inputRef.focus();
    }
  }
listloadData(postobject=''){
  var qs = require('qs');
		
		
			let admin_id = localStorage.getItem("admin_id")
		
    if(postobject!=''){
      var postobject=postobject;
    }else{
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,
        };  
    }
        axios.post(apiUrl+"visitreasons/list",qs.stringify(postobject)).then(res => {
            console.log(res.data,'data')
            if(res.data.status === 'success'){	
              this.setState({  visitreasons: res.data.visitreasons, totalRecord: res.data.records_count ,data_status: res.data.status}, function() {
                 this.visitreasons();
                });
            } else {
               this.setState({  bt_data:[] ,visitreasons: [],}, function() {
                this.visitreasons();
             });
            }
          }); 

}

handleNameClick = (id,name) => {
  this.setState({
    editMode: true,
    editedName: name,
    editItemId: id,
  });
};

handleNameChange = (e) => {
  this.setState({
    editedName: e.target.value,
  });
};

handleSaveName = (id) => {
  console.log(this.state.editedName,'nameedited')
  
  
  var admin_id = localStorage.getItem("admin_id");
  var postObject = {
    id  : id,
    admin_id  : admin_id,
    specialtiesname:  this.state.editedName,
  };
  var qs = require('qs');
  axios.post(apiUrl+"visitreasons/update", qs.stringify(postObject)).then(res => {
    if(res.data.status == "success"){
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
        this.listloadData();
    }else{
      $('.success_message').html('<div class="status_sucess"><h3>Something went wrong</h3></div>');
    }

    setTimeout(
      function() {
        $('.success_message').html('');
      }
      .bind(this),
      3000
    );
  });
  // Update state and exit edit mode
  this.setState({
    editMode: false,
    editedName: '',
  });
};
deletevisitreasons(){
  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var postObject = {
    id  : this.state.selectid,
    admin_id  : admin_id
  };
      axios.post(apiUrl+"visitreasons/delete",qs.stringify(postObject)).then(res => {
          if(res.data.status === 'success'){
                $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
                setTimeout(
                function() {
                $('.success_message').html('');
                }
                  .bind(this),
                3000
                );
                this.listloadData()
             
      }   
  
}); 

}
confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "Active";
  }else if(status == 'active'){
    status = "Inactive";
  }else{
    status="Delete";
  }
 
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

visitreasons() {
  var visitreasons = this.state.visitreasons;
  
  var data_status = this.state.data_status;

  if (visitreasons !== undefined && visitreasons !== null) {
    if (data_status === 'success') {
      if (Object.keys(visitreasons).length > 0) {
        const userlistDetails = visitreasons?.map((visitreasons, Index) => {
          console.log(visitreasons, 'testinsurancne');
          let sno = Index + 1;
          if (visitreasons.status === 'A') {
            var status = 'active';
          } else {
            status = 'inactive';
          }

          return (
            <tr key={visitreasons.id}>
              <td>{sno}</td>
              <td>{this.state.editMode && visitreasons.id === this.state.editItemId ? (
                <input
                  type="text"
                  value={this.state.editedName}
                  onChange={this.handleNameChange}
                  onBlur={() => this.handleSaveName(visitreasons.id)}
                  ref={(input) => this.inputRef = input} 
                />
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleNameClick(visitreasons.id, visitreasons.name)}
                >
                  {visitreasons.name}
                </span>
              )}</td>
              <td>{status}</td>
              <td className="actiongroup">

                {status === 'active' ? (
                  <Link onClick={this.confirmPopup.bind(this, visitreasons.id, status)} className="" title="Status">
                    <i className="ft-unlock" aria-hidden="true"></i>
                  </Link>
                ) : (
                  <Link onClick={this.confirmPopup.bind(this, visitreasons.id, status)} className="" title="Status">
                    <i className="ft-lock" aria-hidden="true"></i>
                  </Link>
                )}
                <Link to={{ pathname: '/visit-edit/'+visitreasons.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                 <Link onClick={this.confirmPopup.bind(this,visitreasons.id,'delete')} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>
                    
              </td>
            </tr>
          );
        });
        return userlistDetails;
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    } else {
      return (
        <tr>
          <td colSpan="9" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  } else {
    return (
      <tr>
        <td colSpan="11" className="v-align-nr">
          No Result
        </td>
      </tr>
    );
  }
}
 
  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
   this.listloadData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadData(postObject)
  });
    

}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

activatespecialtieslist(){
    console.log(this.state.selectedStatus,'statusssssssssss');
	let stat = ''
		if(this.state.selectedStatus ==='Inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  : this.state.selectid,
		      status: stat
        };
      axios.post(apiUrl+"visitreasons/changestatus", qs.stringify(postObject)).then(res => {
        if(res.data.status == "success"){
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
           this.listloadData()
           setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }else{
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
        }
      });
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    if(this.state.selectedStatus=="Delete"){
      this.deletevisitreasons()
    }else{
      this.activatespecialtieslist();
    }
    
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"user/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}

  
  render()  {
    const datas = this.state.bt_data;
   
		const columns = [
			{dataField: 'id', text: 'ID', hidden: true}, 
			{dataField: 'sno', text: 'S.No', editable: false}, 
			{ dataField: 'name', text: 'Name', editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex, columnIndexInHeaders) => {
        return (
          <input
            {...editorProps}
            value={value}
            onChange={(e) => {
              const updatedData = [...datas];
              updatedData[rowIndex][column.dataField] = e.target.value;
              this.setState({ bt_data: updatedData });
            }}
          />
        );
      } },
			{dataField: 'status', text: 'Status', editable: false},
			{dataField: 'action', text: 'Actions', editable: false}
		];

		let mystyle = {
		    color: "white",
		    backgroundColor: "#3E3E3C",
		    padding: "10px",		  
			minWidth: "49px",
			height: "37px",
			lineHeight: "0px",
			textTransform: "capitalize"
	
		};

    return (
      <div className="wrapper"> 
    <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the Visit Reason?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="visit" />  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
          <h3>Visit Reasons</h3>
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 <a className="" href='/visit-add'>Add &nbsp;&nbsp;</a>
           {/* <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link> */}
          </div>
        </div>

        <div className="car-listing-row">
          
        <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.visitreasons()}</tbody>
          </Table>
            <div className="text-center">
              <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalRecord}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              />
            </div>
        </div>
                      
      </div>  
    </div>  
  </div>
    
    </div>
    );
  }
}

export default List;